import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ReactGA from "react-ga4";

import Home from "./pages/Home";
import Privacy from "./pages/Privacy";
import Download from "./pages/Download";
import PlayStoreWaitlist from "./pages/PlayStoreWaitlist";
import Support from "./pages/Support";

ReactGA.initialize("G-JHR0PPN1H5");

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/download" element={<Download />} />
        <Route path="/waitlist" element={<PlayStoreWaitlist />} />
        <Route path="/support" element={<Support />} />
      </Routes>
    </Router>
  );
}

export default App;
