import React from "react";
import "./Privacy.css";

export default function Privacy() {
  return (
    <div className="privacyPolicyDiv">
      <h1 className="privacyParagraph">Privacy Policy</h1>
      <p className="privacyParagraph">Effective Date: November 1, 2022</p>
      <p className="privacyParagraph">
        ChowDown, Inc. (collectively, “ChowDown,” “we,” “us,” or “our”) is
        committed to protecting your privacy. This Privacy Policy (“Policy”)
        explains how we collect, use, disclose and protect Personal Information
        (as it is defined below) of those that engage with our Services, as
        defined below, (“Users”). It applies to all Personal Information
        collected by us on www.thechowdownapp.com and the ChowDown mobile
        application. ChowDown may collect Personal Information to help you place
        and receive orders and to improve the ChowDown service.
      </p>
      <p className="privacyParagraph">
        If you are a ChowDown User, your access to and use of our Services are
        subject to our Terms and Conditions, which define some of the terms used
        throughout this Policy. Please make sure that you have carefully read
        and understand the Terms and Conditions before you use our Services. By
        using our Services, you accept the Terms and Conditions, and accept our
        privacy practices described in this Policy. If you do not feel
        comfortable with any part of this Policy or our Terms and Conditions,
        you must not use or access our Services.
      </p>
      <p className="privacyParagraph">
        Our Services may change from time to time. As a result, we reserve the
        right to update or modify this Policy at any time and from time to time
        without prior notice. If we make any material changes to the way we
        process your Personal Information, we will notify you before such
        changes are effective. If you object to any changes, you must cease
        using our Services. We recommend you review this Policy periodically.
        This Policy became effective on the date indicated at the top of this
        page. Your continued use of our Services after any changes or revisions
        to this Policy indicates your agreement to the terms of the revised
        Policy.
      </p>
      <p className="privacyParagraph boldTitle">
        Collection of Personal Information
      </p>
      <p className="privacyParagraph">
        When you use our Services, we may collect Personal Information about
        you. The types of Personal Information that we may collect about you
        are:
      </p>
      <p className="privacyParagraph">
        1a. Information You Provide to Us Directly
      </p>
      <p className="privacyParagraph">
        You may provide your Personal Information to us when you use our
        Services, including when you register with ChowDown, place orders
        through our Services, contact us with inquiries, respond to surveys, or
        otherwise access or use our Services. This information may include:
      </p>
      <p className="bulletItem">
        • Personal Identifiers and records information – We may collect
        information such as name, alias, email address, postal or delivery
        address, account name, phone number, or other similar identifiers.
      </p>
      <p className="bulletItem">
        • Characteristics of protected classifications under California or
        federal law – We may collect your age, year or date of birth to enable
        us, for example, to determine if you are eligible to make purchases of
        alcohol.
      </p>
      <p className="bulletItem">
        • Commercial Information – When you place an order through our Services,
        we collect information related to that order. This may include
        information such as items purchased, special instructions, date and time
        of order, subtotal, photos that you provide to ChowDown, or other
        purchasing or consuming histories or tendencies.
      </p>
      <p className="bulletItem">
        • Payment Information – Payment information collected such as bank name,
        payment method, or other financial information. We use a payment
        processor that collects your payment information and processes payments
        made on or through the Services. We obtain limited information about
        your payment card from our payment processor, such as the last four
        digits of your card and the expiration date.
      </p>

      <p className="privacyParagraph" style={{ marginTop: 10 }}>
        1b. Information Received From You as Part of Your Communications
      </p>
      <p className="privacyParagraph">
        When you use our Services (for example on our Site), complete electronic
        forms, communicate with or contact us, by online chat, email, phone or
        text, we may automatically collect and store certain information about
        you and the activity you engaged in, for example: your name and contact
        information; your order details; information that you voluntarily
        provide to us; the nature of your communication; the purpose of the
        interaction, and the action we took in response to your inquiry or
        request.
      </p>
      <p className="privacyParagraph">
        1c. Information Collected Automatically
      </p>
      <p className="privacyParagraph">
        We also may collect and store certain information about you and your
        device(s) automatically when you access or use our Site and Services.
        This information may include:
      </p>
      <p className="bulletItem">
        • Technical Information – We collect technical information associated
        with your activity on our Site and may include information related to
        your browser and operating system, IP address (the Internet address of
        your computer), unique device identifiers, personal and online
        identifiers, and other information such as your device type and version
        of the app that is in use.
      </p>
      <p className="bulletItem">
        • Site Usage Information – This may include the webpage that you were
        visiting before accessing our Site or mobile application, the pages or
        features of our Site or mobile application that you browsed to inform us
        which part of our Site, app and Services you visit and how much time you
        spend there.
      </p>
      <p className="bulletItem">
        • Site Preferences – We collect information about your preferences to
        make your use of the Site more productive through the use of cookies.
      </p>

      <p className="privacyParagraph" style={{ marginTop: 10 }}>
        1d. Location Information
      </p>
      <p className="privacyParagraph">
        When you use the Services, we may collect and store general location
        information (such as IP address). We may also collect route information
        and delivery pick-up or drop-off information. If you permit the Services
        to access location services through the permission system used by your
        mobile operating system (“Platform”) or browser, we may also collect and
        store the precise location of your device when the ChowDown app is
        running in the foreground or background of your device. This information
        may be used to help you select your delivery address, to provide more
        accurate deliveries of your orders, to provide recommendations to you,
        and to enhance your user experience and improve the services. You can
        choose whether to enable the location tracking feature through the
        settings on your device or Platform or when prompted by the ChowDown
        mobile app.{" "}
      </p>
      <p className="privacyParagraph boldTitle">
        Use of Your Personal Information
      </p>
      <p className="privacyParagraph">
        We may use the information we collect about you for various purposes.
      </p>
      <p className="privacyParagraph">3a. To Provide You with the Services</p>
      <p className="privacyParagraph">
        We use your information to provide you the Services. For example, we use
        credit card information to complete a transaction or an address to
        fulfill your delivery. If the applicable information is to be provided
        or Service is to be performed by a third party, then we will disclose
        the applicable Personal Information to the third party providing the
        information for performing the applicable Services. Your information may
        be available or provided to third-party or service providers that are
        contractually obligated to protect your information as disclosed in this
        Policy. Payment information may be shared with your bank to validate
        your payment card.
      </p>
      <p className="privacyParagraph">
        3b. To Maintain, Improve, and Personalize the Services
      </p>
      <p className="privacyParagraph">
        We use your information for our everyday business operations such as
        auditing, administration of the Services, forum management, fulfillment,
        and analytics. Your information may also be used to improve the content
        and/or functionality of the Services. We may use your information to
        help us enhance offerings for ChowDown. We also use your information to
        personalize your experience. For example, we may personalize the content
        and features you see when visiting our Services. In addition, we may
        personalize advertisements, marketing communications, and
        recommendations to make them more tailored to your interests.
      </p>
      <p className="privacyParagraph">3c. To Communicate with You</p>
      <p className="privacyParagraph">
        We use your information to communicate with you. For example, we may
        send you text messages or other notifications about the status of your
        orders and deliveries. We may also contact you with promotional
        offerings or other communications that may be of interest to you. If we
        send you marketing emails or texts about services that may interest you,
        each email or text will contain instructions permitting you to “opt-out”
        of receiving future email or text marketing communications.
      </p>
      <p className="privacyParagraph">
        In the event you contact us, we use certain information to respond to
        your questions and assist you with any issues that may arise in your use
        of the Services.{" "}
      </p>
      <p className="privacyParagraph">
        3d. For Account and Network Security Purposes
      </p>
      <p className="privacyParagraph">
        We care about keeping you secure and safe while using our Services.
        Keeping you safe requires us to process your Personal Information, such
        as your device information, activity information and other relevant
        information. We use such information to combat spam, malware, malicious
        activity or security risks; improve and enforce our security measures;
        and to monitor and verify your identity so that unauthorized users do
        not gain access to your information.
      </p>
      <p className="privacyParagraph">
        3e. To Maintain Legal and Regulatory Compliance
      </p>
      <p className="privacyParagraph">
        Our Services are subject to certain laws and regulations which may
        require us to process your Personal Information. For example, we process
        your Personal Information to pay our taxes, to fulfill our business
        obligations, ensure compliance with employment and recruitment laws or
        as necessary to manage risk as required under applicable law.
      </p>
      <p className="privacyParagraph">
        3f. To Enforce Compliance with Our Agreements and Policies
      </p>
      <p className="privacyParagraph">
        When you access or use our Services, you are bound to our Terms and
        Conditions and this Policy. To ensure you comply with them, we process
        your Personal Information by actively monitoring, investigating,
        preventing and mitigating any alleged or actual prohibited, illicit or
        illegal activities on our Services. We also process your Personal
        Information to: investigate, prevent or mitigate violations of our
        internal terms, agreements or policies; enforce our agreements with
        third parties and business partners.
      </p>
      <p className="privacyParagraph">
        3g. To Protect You, Others, and Our Business
      </p>
      <p className="privacyParagraph">
        We use your information to protect you, others, and our business,
        including, without limitation, using information for fraud prevention,
        for enforcement of our Terms of Service, to comply with the law, and to
        protect against security risks.
      </p>
      <p className="privacyParagraph">3h. For Our Business Purposes</p>
      <p className="privacyParagraph">
        We may use your information for any other purpose disclosed to you at
        the time we collect the information, or otherwise with your consent. 
      </p>
      <p className="privacyParagraph boldTitle">California Residents</p>
      <p className="privacyParagraph">
        Pursuant to the California Consumer Privacy Act of 2018, below is a
        summary of the Personal Information we collected from consumers, the
        reason we collected the Personal Information, where we obtained the
        Personal Information we collected, and the third parties with whom we
        may share consumer Personal Information. The section references relate
        to the sections above in this Policy.
      </p>
      <p className="privacyParagraph">
        As described further in our Privacy Policy, in the preceding twelve
        months, we or our service providers may have collected the below
        categories of personal information for business or commercial purposes:
      </p>
      <p className="bulletItem">
        • Identifiers such as a name, address, unique personal identifier,
        email, phone number;
      </p>
      <p className="bulletItem">
        • Commercial information such as records of products or services
        purchased, obtained, or considered;
      </p>
      <p className="bulletItem">
        • Geolocation data that is sufficient to identify a precise physical
        location;
      </p>
      <p className="bulletItem">
        • Sensory data, such as audio, electronic, visual, or other similar
        information;
      </p>
      <p className="bulletItem">
        • Inferences about preferences, characteristics, psychological trends,
        predispositions, behavior, attitudes, intelligence, abilities, and
        aptitudes.
      </p>
      <p className="privacyParagraph">
        We collect the categories of personal information identified above from
        the following sources: (1) directly from you; and (2) through your use
        of the Services.
      </p>
      <p className="privacyParagraph">
        As explained above, we collect the categories of personal information
        identified above for the following business and commercial purposes:
      </p>
      <p className="bulletItem">• To provide you with the Services; </p>
      <p className="bulletItem">
        • To maintain, improve, and personalize the Services;
      </p>
      <p className="bulletItem">• To communicate with you;</p>
      <p className="bulletItem">• For account and network security purposes;</p>
      <p className="bulletItem">
        • To maintain legal and regulatory compliance;
      </p>
      <p className="bulletItem">
        • To enforce compliance with our Terms and Conditions and this Policy;
      </p>
      <p className="bulletItem">
        • To protect you, others, and our business; and
      </p>
      <p className="bulletItem">
        • For our business purposes disclosed to you at the time we collect or
        receive the information, or otherwise with your consent.
      </p>
    </div>
  );
}
