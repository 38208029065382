import React, { useState } from "react";
import {
  addDoc,
  collection,
  serverTimestamp,
  doc,
  getDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { Button, Spinner } from "react-bootstrap";

import { db } from "../firebase-config";

function PlayStoreWaitlist() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [successfullySignedUp, setSuccessfullySignedUp] = useState(false);

  const signUp = async () => {
    setLoading(true);
    const playStoreWaitlistRef = collection(db, "playStoreWaitlist");

    try {
      const newScan = await addDoc(playStoreWaitlistRef, {
        createdAt: serverTimestamp(),
        email: email,
      });
      setSuccessfullySignedUp(true);
    } catch (error) {
      console.log("eror", error);
    }

    setLoading(false);
  };

  return (
    <div style={{ padding: 20, paddingTop: 200 }}>
      <img
        style={{
          objectFit: "contain",
          height: 50,
          borderRadius: 10,
          //   marginTop: 20,
          marginBottom: 10,
          //   alignSelf: "center",
        }}
        src={require("../assets/icon.png")}
        // src='https://firebasestorage.googleapis.com/v0/b/clothing-for-ukraine.appspot.com/o/Clothing%20For%20Ukraine%20Logo.jpg?alt=media&token=c75af262-0d8a-4184-b18e-3b7de7fd5363'
        alt=""
      />
      <div style={{ maxWidth: 750, margin: "auto" }}>
        <h1>ChowDown is launching on the Play Store November 14! 🎉</h1>
        <p>
          Sign up below to receive an email when ChowDown for Android is
          available:
        </p>
        <input
          style={{
            // marginTop: "10px"
            width: "100%",
          }}
          placeholder="Email Address"
          onChange={(event) => {
            setEmail(event.target.value);
          }}
        />
        <Button
          disabled={loading}
          onClick={signUp}
          style={{
            width: "100%",
            // paddingTop: "10px",
            // paddingBottom: "10px",
            // fontWeight: "bold",
            marginTop: 10,
          }}
          variant="success"
        >
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            "Sign Up"
          )}
        </Button>
        {successfullySignedUp && (
          <p style={{ color: "green", marginTop: 10 }}>
            ✔️ You have successfully signed up to be notified when ChowDown
            releases on the Play Store!
          </p>
        )}
      </div>
    </div>
  );
}

export default PlayStoreWaitlist;
