import { useEffect } from "react";
// import { Container, Row, Col, Stack } from "react-bootstrap";
import {
  addDoc,
  collection,
  serverTimestamp,
  doc,
  getDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { isAndroid, isIOS } from "react-device-detect";
import { useSearchParams, useNavigate } from "react-router-dom";

import "./Download.css";
import { db } from "../firebase-config";

function Download() {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    console.log("useeffect runnign");
    // console.log("searchParams", searchParams.get("utm_source"));
    // console.log("searchParams", searchParams.get("utm_medium"));
    // console.log("searchParams", searchParams.get("utm_campaign"));
    const qrScansRef = collection(db, "qrScans");

    const recordQrScan = async () => {
      try {
        const newScan = await addDoc(qrScansRef, {
          createdAt: serverTimestamp(),
          os: isAndroid ? "android" : isIOS ? "ios" : "unknown",
          utm_source: searchParams.get("utm_source"),
          utm_medium: searchParams.get("utm_medium"),
          utm_campaign: searchParams.get("utm_campaign"),
        });

        //auto navigate
        if (isAndroid) {
          window.location.replace(
            "https://play.google.com/store/apps/details?id=com.ChowDown"
          );
        }
        if (isIOS) {
          window.location.replace(
            "https://apps.apple.com/us/app/chowdown-tasty-chinese-food/id6444165484"
          );
        }
      } catch (error) {
        console.log("eror", error);
      }
    };
    recordQrScan();
  }, []);

  const appStoreClick = async () => {
    console.log("appstoreclick");
    //record click in db
    //redirect to app store
    const downloadLinkClicksRef = collection(db, "downloadLinkClicks");

    try {
      const newClick = await addDoc(downloadLinkClicksRef, {
        buttonType: "ios",
        createdAt: serverTimestamp(),
      });
      window.location.replace(
        "https://apps.apple.com/us/app/chowdown-tasty-chinese-food/id6444165484"
      );
    } catch (error) {
      console.log("eror", error);
    }
  };
  const playStoreClick = async () => {
    console.log("playstoreclick");
    //record click in db
    //redirect to app store
    const downloadLinkClicksRef = collection(db, "downloadLinkClicks");

    try {
      const newClick = await addDoc(downloadLinkClicksRef, {
        buttonType: "android",
        createdAt: serverTimestamp(),
      });

      //replace the app store link below with the Play Store link
      window.location.replace(
        "https://play.google.com/store/apps/details?id=com.ChowDown"
      );

      // navigate("/waitlist");
    } catch (error) {
      console.log("eror", error);
    }
  };

  return (
    <div style={{ paddingTop: 200 }}>
      <div className="centeredDiv">
        <img
          style={{
            objectFit: "contain",
            height: 50,
            borderRadius: 10,
            marginTop: 20,
          }}
          src={require("../assets/icon.png")}
          // src='https://firebasestorage.googleapis.com/v0/b/clothing-for-ukraine.appspot.com/o/Clothing%20For%20Ukraine%20Logo.jpg?alt=media&token=c75af262-0d8a-4184-b18e-3b7de7fd5363'
          alt=""
        />
      </div>
      <div className="centeredDiv">
        <img
          onClick={appStoreClick}
          className="appButton"
          style={{
            objectFit: "contain",
            maxWidth: "90%",
            width: 250,
            marginTop: 25,
          }}
          src={require("../assets/appStoreLogo.png")}
          // src='https://firebasestorage.googleapis.com/v0/b/clothing-for-ukraine.appspot.com/o/Clothing%20For%20Ukraine%20Logo.jpg?alt=media&token=c75af262-0d8a-4184-b18e-3b7de7fd5363'
          alt=""
        />
      </div>
      <div className="centeredDiv">
        <img
          onClick={playStoreClick}
          className="appButton"
          style={{
            objectFit: "contain",
            maxWidth: "90%",
            width: 250,
            marginTop: 20,
            //   top: "50%",
            //   margin: "auto",
          }}
          src={require("../assets/playLogo.png")}
          // src='https://firebasestorage.googleapis.com/v0/b/clothing-for-ukraine.appspot.com/o/Clothing%20For%20Ukraine%20Logo.jpg?alt=media&token=c75af262-0d8a-4184-b18e-3b7de7fd5363'
          alt=""
        />
      </div>
      {/* <Stack direction="horizontal" gap={3} style={{ padding: 10 }}>
        <img
          style={{ objectFit: "contain", height: 38 }}
          src={require("../assets/CLogo.png")}
          // src='https://firebasestorage.googleapis.com/v0/b/clothing-for-ukraine.appspot.com/o/Clothing%20For%20Ukraine%20Logo.jpg?alt=media&token=c75af262-0d8a-4184-b18e-3b7de7fd5363'
          alt=""
        />{" "}
      </Stack> */}
      {/* <Row style={{ width: "100%" }}>
        <Col
          style={{
            // backgroundColor: "red",
            paddingRight: "10vw",
            paddingLeft: "10vw",
            paddingTop: "10vw",
            paddingBottom: "10vw",
            // justifyContent: "50%",
            // height: "100vh",
          }}
          sm={7}
        >
          <div>
            <h1 className="titley">$10 Chinese food & free delivery</h1>
          </div>
          <div>Serving Davis, CA from 11am-9pm</div>
          <Row style={{ marginTop: 20 }}>
            <Col>
              <img
                className="appButton"
                style={{ objectFit: "contain", maxWidth: "100%" }}
                src={require("../assets/appStoreLogo.png")}
                // src='https://firebasestorage.googleapis.com/v0/b/clothing-for-ukraine.appspot.com/o/Clothing%20For%20Ukraine%20Logo.jpg?alt=media&token=c75af262-0d8a-4184-b18e-3b7de7fd5363'
                alt=""
              />
            </Col>
            <Col>
              <img
                className="appButton"
                style={{
                  objectFit: "contain",
                  maxWidth: "100%",

                  //   top: "50%",
                  //   margin: "auto",
                }}
                src={require("../assets/playLogo.png")}
                // src='https://firebasestorage.googleapis.com/v0/b/clothing-for-ukraine.appspot.com/o/Clothing%20For%20Ukraine%20Logo.jpg?alt=media&token=c75af262-0d8a-4184-b18e-3b7de7fd5363'
                alt=""
              />
            </Col>{" "}
          </Row>
        </Col>
        <Col sm={5} className="rightColumn">
          <img
            className="homeImage"
            style={{ objectFit: "contain", maxWidth: "100%", borderRadius: 10 }}
            src={require("../assets/homePic.jpg")}
            alt=""
          />
        </Col>
      </Row> */}
    </div>
  );
}

export default Download;
