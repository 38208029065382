// import "bootstrap/dist/css/bootstrap.min.css";

import { Container, Row, Col, Stack } from "react-bootstrap";
import "./Home.css";
function Home() {
  return (
    <div style={{ margin: 0, alignItems: "center", padding: 10 }}>
      <Stack direction="horizontal" gap={3} style={{ padding: 10 }}>
        <img
          style={{ objectFit: "contain", height: 38 }}
          src={require("../assets/CLogo.png")}
          // src='https://firebasestorage.googleapis.com/v0/b/clothing-for-ukraine.appspot.com/o/Clothing%20For%20Ukraine%20Logo.jpg?alt=media&token=c75af262-0d8a-4184-b18e-3b7de7fd5363'
          alt=""
        />{" "}
      </Stack>
      <Row style={{ width: "100%" }}>
        <Col
          style={{
            // backgroundColor: "red",
            paddingRight: "10vw",
            paddingLeft: "10vw",
            paddingTop: "10vw",
            paddingBottom: "10vw",
            // justifyContent: "50%",
            // height: "100vh",
          }}
          sm={7}
        >
          <div>
            <h1 className="titley">$10 Chinese food & free delivery</h1>
          </div>
          <div>Serving Davis, CA from 11am-9pm</div>
          <Row style={{ marginTop: 20 }}>
            <Col>
              <img
                className="appButton"
                style={{ objectFit: "contain", maxWidth: "100%" }}
                src={require("../assets/appStoreLogo.png")}
                // src='https://firebasestorage.googleapis.com/v0/b/clothing-for-ukraine.appspot.com/o/Clothing%20For%20Ukraine%20Logo.jpg?alt=media&token=c75af262-0d8a-4184-b18e-3b7de7fd5363'
                alt=""
              />
            </Col>
            <Col>
              <img
                className="appButton"
                style={{
                  objectFit: "contain",
                  maxWidth: "100%",

                  //   top: "50%",
                  //   margin: "auto",
                }}
                src={require("../assets/playLogo.png")}
                // src='https://firebasestorage.googleapis.com/v0/b/clothing-for-ukraine.appspot.com/o/Clothing%20For%20Ukraine%20Logo.jpg?alt=media&token=c75af262-0d8a-4184-b18e-3b7de7fd5363'
                alt=""
              />
            </Col>{" "}
          </Row>
        </Col>
        <Col sm={5} className="rightColumn">
          <img
            className="homeImage"
            style={{ objectFit: "contain", maxWidth: "100%", borderRadius: 10 }}
            src={require("../assets/homePic.jpg")}
            // src='https://firebasestorage.googleapis.com/v0/b/clothing-for-ukraine.appspot.com/o/Clothing%20For%20Ukraine%20Logo.jpg?alt=media&token=c75af262-0d8a-4184-b18e-3b7de7fd5363'
            alt=""
          />
        </Col>
      </Row>
    </div>
  );
}

export default Home;
